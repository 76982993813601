.container {
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    width: 100vw !important;
    justify-content: center;
    max-width: 1400px;
}

.letter-maker {
    flex: 1 1 48%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    margin: 0 auto;
    height: 80%;
    padding: 1%;
    padding-top: 70px;
}

.letter-maker input {
    align-self: flex-start;
    border-radius: 10px;
    font-size: 20px;
    padding: 10px;
    width: 100%;
    min-width: 200px;
    margin-top: 20px;
    border: none;
    box-shadow: inset 1px 2px 5px 3px rgb(0,0,0,.2);
    background-color: rgb(255,255,255,.6);
}

.letter-maker textarea {
    border-radius: 10px;
    margin-top: 20px;
    width: 100%;
    font-size: 20px;
    padding: 10px;
    flex-grow: 1;
    border: none;
    box-shadow: inset 1px 2px 5px 3px rgb(0,0,0,.2);
    background-color: rgb(255,255,255,.6);
}

.letter-maker button {
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    font-size: 20px;
    border-radius: 10px;
    border: none;
    background-color: rgb(255,255,255,.6);
    color: grey;
}

.letter-maker button:hover {
    box-shadow: 2px 4px 10px 5px rgb(0,0,0,.2);
}

.preview {
    min-width: 200px;
    flex: 1 1 48%;
    padding: 1%;
}