.bg-container {
    position: absolute;
    width: 100vw;
    height:100vh;
    background: linear-gradient(-45deg, #ff98fa, #e73c7e, #cb47ff, #8a60ff);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    z-index: 0;
}
  
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.page-content {
    height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-family: monospace;
    color: white;
    opacity: 1;
}

#password {
    width: 100%;
    height: 2rem;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    font-size: 1rem;
    font-family: monospace;
    color: white;
    opacity: .8;
}

#password:focus,
#password:active {
    outline: none !important;
}

#password::placeholder {
    color: white;
}

.box {
    border: 1px solid white;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    box-shadow: 5px 10px 30px 15px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
}

#blur {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(5px);
    backdrop-filter: blur(10px);
    border-radius: 20px;
}

.box h1 {
    font-size: 1.5rem;
}


